import React, { useState, useEffect } from "react";
import TopNavigation from "@awsui/components-react/top-navigation";
import { getCurrentUserInfo } from "../resources/auth";
import "../styles/topNavigation.scss";

export default function TopNav() {
  const [fullName, setFullName] = useState(null);
  const [alias, setAlias] = useState(null);

  let title = "What's New";
  let className = "";

  if (process.env.REACT_APP_ENV === "staging") {
    title = "What's New (STAGING)";
    className = "red-background";
  } else if (process.env.NODE_ENV === "development") {
    title = "What's New (DEVELOPMENT)";
    className = "red-background";
  }

  useEffect(() => {
    if (process.env.NODE_ENV === "development") {
      setFullName("Developer");
      setAlias("developer");
      return;
    }

    getCurrentUserInfo().then((user) => {
      if (user) {
        setFullName(
          user.attributes.given_name + " " + user.attributes.family_name
        );
        setAlias(user.attributes.email.split("@")[0]);
      }
    });
  }, []);

  return (
    <TopNavigation
      className={`${className}`}
      identity={{
        href: "/",
        title: `${title}`,
        logo: {
          src: "/megaphone.png",
          alt: "What's New",
        },
      }}
      utilities={[
        {
          type: "button",
          text: `${fullName}`,
          href: `https://phonetool.amazon.com/users/${alias}`,
          iconName: "user-profile",
          external: false,
          externalIconAriaLabel: " (opens in a new tab)",
        },
      ]}
      i18nStrings={{
        searchIconAriaLabel: "Search",
        searchDismissIconAriaLabel: "Close search",
        overflowMenuTriggerText: "More",
        overflowMenuTitleText: "All",
        overflowMenuBackIconAriaLabel: "Back",
        overflowMenuDismissIconAriaLabel: "Close menu",
      }}
    />
  );
}
