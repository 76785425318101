function Articles(props) {
  return (
    <>
      {props.articles.map((article, index) => (
        <>
          <div key={index} className="article">
            <strong className="article-title">{article.Title}</strong>
            <p dangerouslySetInnerHTML={{ __html: article.Description }} />
            Read more:
            {' '}
            <a href={article.Link}>{article.Link}</a>
          </div>
          <br />
        </>
      ))}
    </>
  );
}

export default Articles;
