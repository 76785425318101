import { useEffect, useState } from "react";
import Box from "@awsui/components-react/box";
import Button from "@awsui/components-react/button";
import Container from "@awsui/components-react/container";
import Header from "@awsui/components-react/header";
import SpaceBetween from "@awsui/components-react/space-between";
import Table from "@awsui/components-react/table";

import moment from "moment";
import { listTemplates, postUserAction } from "../resources/api";

const columnDefinitions = [
  {
    id: "title",
    header: "Title",
    cell: (item) => item.Title,
    minWidth: 400,
  },
  {
    id: "SK",
    header: "Timestamp",
    sortingField: "Timestamp",
    cell: (item) => {
      const dt = parseInt(item.SK.split("#")[1]);
      return moment(dt).fromNow();
    },
    width: 200,
  },
  {
    id: "actions",
    cell: (item) => (
      <Button
        variant="primary"
        formAction="none"
        href={`/template/${item.SK.replace("template#", "")}`}
        iconAlign="undefined"
        iconName="undefined"
      >
        Edit
      </Button>
    ),
    header: "Actions",
    minWidth: 100,
  },
];

const tableEmpty = (
  <Box textAlign="center" color="inherit">
    <Box variant="p" color="inherit">
      No documents to display.
    </Box>
  </Box>
);

function ListTemplates() {
  const [data, setData] = useState([]);
  const [tableLoading, setTableLoading] = useState(true);

  useEffect(() => {
    listTemplates()
      .then((data) => {
        console.log(data);
        setData(data);
        setTableLoading(false);
        postUserAction("listTemplates");
      })
      .catch(console.error);
  }, []);

  return (
    <Container header={<Header variant="h1">Templates</Header>}>
      <SpaceBetween direction="vertical" size="s">
        <Button
          iconName="refresh"
          onClick={() => {
            setTableLoading(true);
            listTemplates()
              .then((data) => {
                setData(data);
                setTableLoading(false);
              })
              .catch(console.error);
          }}
        >
          Refresh
        </Button>
        <Table
          loadingText="Loading templates..."
          columnDefinitions={columnDefinitions}
          items={data}
          empty={tableEmpty}
          loading={tableLoading}
          resizableColumns={true}
        />
      </SpaceBetween>
    </Container>
  );
}

export default ListTemplates;
