import { getConfig } from './api';
import { configure } from './sentryfetch/index';
import { isDevelopment, isStaging } from './util';

const SUDS_ENDPOINT_PROD = "https://prod.gateway.suds.wwro.aws.dev";
const SUDS_ENDPOINT_BETA = 'https://beta.gateway.suds-int.wwro.aws.dev';
// const SUDS_ENDPOINT_GAMMA = 'https://gamma.gateway.suds.wwro.aws.dev';
// const SUDS_ENDPOINT_DEV = 'https://anhegde.gateway.suds-int.wwro.aws.dev'

const getSudsEndpoint = () => {
  if (isDevelopment() || isStaging()) {
    try {
      getConfig().then((c) => c.data.suds_endpoint);
    } catch (error) {
      console.error(error);
      return SUDS_ENDPOINT_BETA;
    }
  }
  return SUDS_ENDPOINT_PROD;
}

export type CustomerInfo = {
  name: string;
  id: string;
}

export type TerritoryData = {
  search: TerritorySearch;
}

export type TerritorySearch = {
  nodes: TerritoryNode[];
}

export type TerritoryNode = {
  id: string;
  scope: string;
  territoryCode: string;
  subregionCode: string;
}

const sFetch = configure({
  getEndpointOverride: () => getSudsEndpoint(),
  followMidwayStepUp: true,
  followMidwayStepUpOptions: { mode: 'modal' }
});

/**
 * Returns the personId of the authenticated user.
 *
 * @returns personId
 */
export const getMyPersonId = async () => {
  console.log('Getting my personId from SUDS');

  if (isDevelopment()) {
    return '3533e439-e018-4479-bf0f-06ed64b471e6'; // use for beta; it has some assignments 
  }

  const query = `query {
    me {
      id
    }
  }`;

  const response = await sFetch(`/graphql`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ query })
  });
  const result = await response.json();
  return result.data.me.id;
}

/**
 * Returns the territories to which personId is aligned.
 *
 * @param personId - personId
 * @returns Array of territory names
 */
export const getMyTerritories = async (personId: string): Promise<string[]> => {
  console.log('Getting my territories from SUDS');

  if (isDevelopment()) {
    return Promise.resolve(territories);
  }

  const query = `query {
    search(
      input: {
        searchScopes: [Registry_Assignment]
        filter: {
          filterType: "COMPOUND"
          operator: "AND"
          filters: [
            {
              filterType: "STRING"
              operator: "EXACT_MATCH"
              key: "personId"
              value: "${personId}"
            }
            {
              filterType: "STRING"
              operator: "EXACT_MATCH"
              key: "scope"
              value: "territory"
            }
          ]
        }
        limit: 5
      }
    ) {
      nodes {
        ... on Registry_Assignment {
          id
          scope
          territoryCode
          subregionCode
        }
      }
    }
  }`;

  console.log("Query: ", JSON.stringify({ query }));

  const response = await sFetch(`/graphql`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ query })
  });
  const result = await response.json();
  console.log(result);

  const territoryData = result.data as TerritoryData;

  const territoryCodes = territoryData.search.nodes.map((node) => node.territoryCode);
  console.log("territoryCodes: ", territoryCodes);
  return territoryCodes;
}

/**
 * Returns the customers belonging to a territory.
 *
 * @param territoryId - territoryId
 * @returns Array of CustomerInfo
 */
export const getMyCustomers = async (territoryId: string): Promise<CustomerInfo[]> => {
  console.log(`Getting customers in territory ${territoryId} from SUDS`);

  if (isDevelopment()) {
    return Promise.resolve(customers);
  }

  const query = `{
    search(
      input: {
        searchScopes: [SFDC_Account]
        filter: {
          filterType: "STRING"
          operator: "EXACT_MATCH"
          key: "territory_Lookup__r.name"
          value: "${territoryId}"
        }
        limit: 50
      }
    ) {
      nodes {
        ... on SFDC_Account {
          name
          id
        }
      }
    }
  }`;

  const response = await sFetch(`/graphql`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ query })
  });
  const result = await response.json();
  return result.data.search.nodes;
}


export const getSpendByService = async (customerId: string) => {
  console.log(`Getting spend data for customer ${customerId} from SUDS`);

  if (isDevelopment()) {
    return spendByService;
  }

  const query = `{
    search(
      input: {
        searchScopes: [SFDC_Account]
        filter: {
          filterType: "STRING"
          operator: "EXACT_MATCH"
          key: "id"
          value: "0010z00001T6sr3AAB"
        }
      }
    ) {
      nodes {
        ... on SFDC_Account {
          awsci_customer {
            spendByService {
              spends {
                customerId
                billingPeriod
                charge
                revenue
              }
              serviceName
            }
          }
        }
      }
    }
  }`;

  sFetch(`/graphql`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ query }),
  })
    .then((response) => response.json())
    .then((resp) => console.log(resp))
    .catch((err) => {
      console.log('Got error from calling suds', err);
    });
}

/*******************************************************************************
TEST DATA
*******************************************************************************/
const territories = ['SMB-LRG-US-SMB-RMP-MXD-FLORIDA05', 'SMB-LRG-US-SMB-SCL-MXD-FLORIDA02'];

const customers: CustomerInfo[] =
  [
    {
      "name": "Sintavia",
      "id": "0010z00001T6sr3AAB"
    },
    {
      "name": "Fracture, LLC",
      "id": "0010z00001ThgaBAAR"
    },
    {
      "name": "Cooper General Global Services",
      "id": "0010z00001UX4veAAD"
    },
    {
      "name": "Datastream Group, Inc",
      "id": "0010z00001UXHs2AAH"
    },
    {
      "name": "ALIVI",
      "id": "0010z00001VCSqIAAX"
    },
    {
      "name": "Ruggable",
      "id": "0010z00001VX80NAAT"
    },
    {
      "name": "ALIVI",
      "id": "0010z00001VXRelAAH"
    },
    {
      "name": "Planhub",
      "id": "0010z00001WIqOGAA1"
    },
    {
      "name": "W&O Supply, Inc.",
      "id": "0010z00001WPmoCAAT"
    },
    {
      "name": "HaystackID",
      "id": "0010z00001Z9jNdAAJ"
    },
    {
      "name": "Bisnow",
      "id": "0010z00001Zb9xKAAR"
    },
    {
      "name": "Alfi",
      "id": "0010z00001ZbZ2FAAV"
    },
    {
      "name": "Digital Ninjaz, Inc.",
      "id": "0010z00001ax4IbAAI"
    },
    {
      "name": "Omniangle",
      "id": "00138000019eZqPAAU"
    },
    {
      "name": "Starmark",
      "id": "0013800001A9i7VAAR"
    },
    {
      "name": "Park Street",
      "id": "0013800001Bi0brAAB"
    },
    {
      "name": "SMART Payment Plan",
      "id": "0013800001Cc9EDAAZ"
    },
    {
      "name": "leadtrust",
      "id": "0013800001CcAi3AAF"
    },
    {
      "name": "Media Distribution Solutions",
      "id": "0013800001DFnprAAD"
    },
    {
      "name": "Drivosity, LLC",
      "id": "0013800001DFolMAAT"
    },
    {
      "name": "Tresta",
      "id": "0013800001DFqOGAA1"
    },
    {
      "name": "dreamwareinc.co",
      "id": "0013800001DflMsAAJ"
    },
    {
      "name": "SMArtX Advisory Solutions LLC",
      "id": "0013800001FGACqAAP"
    },
    {
      "name": "iRemedy Healthcare, Inc.",
      "id": "0013800001G2ZhUAAV"
    },
    {
      "name": "Frontline Insurance",
      "id": "0013800001HEmaGAAT"
    },
    {
      "name": "HYPOWER INC.",
      "id": "0013800001HnxCzAAJ"
    },
    {
      "name": "Oohology",
      "id": "0013800001IGzsWAAT"
    },
    {
      "name": "Quantres",
      "id": "0013800001JUBHTAA5"
    },
    {
      "name": "PATLive",
      "id": "0013800001L5rZ1AAJ"
    },
    {
      "name": "Automotive Broadcasting Network",
      "id": "0013800001LOO5lAAH"
    },
    {
      "name": "At Your Pace Online",
      "id": "0013800001LPQvLAAX"
    },
    {
      "name": "METROPOLIS MAGAZINE",
      "id": "0013800001Mob5vAAB"
    },
    {
      "name": "Ciirus Vacation Rental Software",
      "id": "0013800001NqX0YAAV"
    },
    {
      "name": "Spectrio",
      "id": "0013800001ORRKgAAP"
    },
    {
      "name": "Cloud Stream Music",
      "id": "0013800001ORW72AAH"
    },
    {
      "name": "Infinity Sales Group LLC",
      "id": "0014z00001b2wAHAAY"
    },
    {
      "name": "Obivyo (formerly Hiconversion)",
      "id": "0015000000ZXe87AAD"
    },
    {
      "name": "SmartZip Analytics, Inc.",
      "id": "0015000000ZYWGjAAP"
    },
    {
      "name": "JiffyShirts.com",
      "id": "0015000000dhgFIAAY"
    },
    {
      "name": "319InSight (Previously Networks / Coretomic)",
      "id": "0015000000eTQudAAG"
    },
    {
      "name": "Enplug Inc.",
      "id": "0015000000fPUNfAAO"
    },
    {
      "name": "NetLook inc",
      "id": "0015000000fQ2wBAAS"
    },
    {
      "name": "Macfadden Communications (Dancemedia)",
      "id": "0015000000gqyXoAAI"
    },
    {
      "name": "Hollywood.com",
      "id": "0015000000grbXWAAY"
    },
    {
      "name": "Digital Juice, Inc.",
      "id": "0015000000jGx99AAC"
    },
    {
      "name": "RedGage LLC",
      "id": "0015000000l7tN1AAI"
    },
    {
      "name": "Intelity Solutions",
      "id": "0015000000l7tTiAAI"
    },
    {
      "name": "Market Traders Institute",
      "id": "0015000000mHwC5AAK"
    },
    {
      "name": "Fraudlogix",
      "id": "0015000000ml3cHAAQ"
    },
    {
      "name": "Schellman & Company",
      "id": "0015000000mmIIKAA2"
    }
  ];

const spendByService = {
  "data": {
    "search": {
      "nodes": [
        {
          "name": "Sintavia",
          "id": "0010z00001T6sr3AAB",
          "awsci_customer": {
            "spendByService": [
              {
                "spends": [
                  {
                    "billingPeriod": "2023-01-01T00:00:00.000Z",
                    "charge": 7.14
                  },
                  {
                    "billingPeriod": "2023-02-01T00:00:00.000Z",
                    "charge": 5.15
                  },
                  {
                    "billingPeriod": "2022-12-01T00:00:00.000Z",
                    "charge": 4.41
                  },
                  {
                    "billingPeriod": "2023-03-01T00:00:00.000Z",
                    "charge": 4.95
                  }
                ],
                "serviceName": "AWS Config"
              },
              {
                "spends": [
                  {
                    "billingPeriod": "2023-03-01T00:00:00.000Z",
                    "charge": 0
                  },
                  {
                    "billingPeriod": "2022-12-01T00:00:00.000Z",
                    "charge": 0
                  },
                  {
                    "billingPeriod": "2023-02-01T00:00:00.000Z",
                    "charge": 0
                  },
                  {
                    "billingPeriod": "2023-01-01T00:00:00.000Z",
                    "charge": 0
                  }
                ],
                "serviceName": "AWS Key Management Service"
              },
              {
                "spends": [
                  {
                    "billingPeriod": "2023-01-01T00:00:00.000Z",
                    "charge": 746.4
                  },
                  {
                    "billingPeriod": "2022-12-01T00:00:00.000Z",
                    "charge": 733.14
                  },
                  {
                    "billingPeriod": "2023-03-01T00:00:00.000Z",
                    "charge": 738.37
                  },
                  {
                    "billingPeriod": "2023-02-01T00:00:00.000Z",
                    "charge": 747.19
                  }
                ],
                "serviceName": "Amazon Elastic Compute Cloud"
              },
              {
                "spends": [
                  {
                    "billingPeriod": "2023-03-01T00:00:00.000Z",
                    "charge": 4747.48
                  },
                  {
                    "billingPeriod": "2023-01-01T00:00:00.000Z",
                    "charge": 4748.91
                  },
                  {
                    "billingPeriod": "2023-02-01T00:00:00.000Z",
                    "charge": 4289.72
                  },
                  {
                    "billingPeriod": "2022-12-01T00:00:00.000Z",
                    "charge": 4749.06
                  }
                ],
                "serviceName": "Amazon Elastic Compute Cloud (Linux)"
              },
              {
                "spends": [
                  {
                    "billingPeriod": "2023-01-01T00:00:00.000Z",
                    "charge": 925.87
                  },
                  {
                    "billingPeriod": "2023-02-01T00:00:00.000Z",
                    "charge": 858.42
                  },
                  {
                    "billingPeriod": "2023-03-01T00:00:00.000Z",
                    "charge": 924.26
                  },
                  {
                    "billingPeriod": "2022-12-01T00:00:00.000Z",
                    "charge": 921.63
                  }
                ],
                "serviceName": "AWS Support (Business)"
              },
              {
                "spends": [
                  {
                    "billingPeriod": "2022-11-01T00:00:00.000Z",
                    "charge": 18.08
                  }
                ],
                "serviceName": "AWS Data Transfer"
              },
              {
                "spends": [
                  {
                    "billingPeriod": "2023-01-01T00:00:00.000Z",
                    "charge": 0
                  },
                  {
                    "billingPeriod": "2023-02-01T00:00:00.000Z",
                    "charge": 0
                  },
                  {
                    "billingPeriod": "2023-03-01T00:00:00.000Z",
                    "charge": 0
                  },
                  {
                    "billingPeriod": "2022-12-01T00:00:00.000Z",
                    "charge": 0
                  }
                ],
                "serviceName": "AWS Lambda"
              },
              {
                "spends": [
                  {
                    "billingPeriod": "2023-01-01T00:00:00.000Z",
                    "charge": 0
                  },
                  {
                    "billingPeriod": "2022-12-01T00:00:00.000Z",
                    "charge": 0
                  },
                  {
                    "billingPeriod": "2023-02-01T00:00:00.000Z",
                    "charge": 0
                  },
                  {
                    "billingPeriod": "2023-03-01T00:00:00.000Z",
                    "charge": 0
                  }
                ],
                "serviceName": "Amazon Simple Queue Service"
              },
              {
                "spends": [
                  {
                    "billingPeriod": "2023-03-01T00:00:00.000Z",
                    "charge": 0.01
                  },
                  {
                    "billingPeriod": "2023-01-01T00:00:00.000Z",
                    "charge": 0.01
                  },
                  {
                    "billingPeriod": "2023-02-01T00:00:00.000Z",
                    "charge": 0
                  },
                  {
                    "billingPeriod": "2022-12-01T00:00:00.000Z",
                    "charge": 0.01
                  }
                ],
                "serviceName": "Amazon Simple Notification Service"
              },
              {
                "spends": [
                  {
                    "billingPeriod": "2022-12-01T00:00:00.000Z",
                    "charge": 44.64
                  },
                  {
                    "billingPeriod": "2023-01-01T00:00:00.000Z",
                    "charge": 44.6
                  },
                  {
                    "billingPeriod": "2023-03-01T00:00:00.000Z",
                    "charge": 44.58
                  },
                  {
                    "billingPeriod": "2023-02-01T00:00:00.000Z",
                    "charge": 40.28
                  }
                ],
                "serviceName": "AWS Directory Service"
              },
              {
                "spends": [
                  {
                    "billingPeriod": "2023-01-01T00:00:00.000Z",
                    "charge": 0.08
                  },
                  {
                    "billingPeriod": "2022-12-01T00:00:00.000Z",
                    "charge": 0.07
                  },
                  {
                    "billingPeriod": "2023-02-01T00:00:00.000Z",
                    "charge": 0.08
                  },
                  {
                    "billingPeriod": "2023-03-01T00:00:00.000Z",
                    "charge": 0.09
                  }
                ],
                "serviceName": "Amazon Simple Storage Service"
              },
              {
                "spends": [
                  {
                    "billingPeriod": "2023-02-01T00:00:00.000Z",
                    "charge": 0
                  },
                  {
                    "billingPeriod": "2023-03-01T00:00:00.000Z",
                    "charge": 0
                  },
                  {
                    "billingPeriod": "2022-12-01T00:00:00.000Z",
                    "charge": 0
                  },
                  {
                    "billingPeriod": "2023-01-01T00:00:00.000Z",
                    "charge": 0
                  }
                ],
                "serviceName": "AWS CloudTrail"
              },
              {
                "spends": [
                  {
                    "billingPeriod": "2023-02-01T00:00:00.000Z",
                    "charge": 3.93
                  },
                  {
                    "billingPeriod": "2023-03-01T00:00:00.000Z",
                    "charge": 3.93
                  },
                  {
                    "billingPeriod": "2022-12-01T00:00:00.000Z",
                    "charge": 3.93
                  },
                  {
                    "billingPeriod": "2023-01-01T00:00:00.000Z",
                    "charge": 3.93
                  }
                ],
                "serviceName": "AmazonCloudWatch"
              },
              {
                "spends": [
                  {
                    "billingPeriod": "2023-01-01T00:00:00.000Z",
                    "charge": 212.05
                  },
                  {
                    "billingPeriod": "2023-03-01T00:00:00.000Z",
                    "charge": 212.05
                  },
                  {
                    "billingPeriod": "2022-12-01T00:00:00.000Z",
                    "charge": 212.05
                  },
                  {
                    "billingPeriod": "2023-02-01T00:00:00.000Z",
                    "charge": 191.52
                  }
                ],
                "serviceName": "Amazon Virtual Private Cloud"
              },
              {
                "spends": [
                  {
                    "billingPeriod": "2023-03-01T00:00:00.000Z",
                    "charge": 1927.77
                  },
                  {
                    "billingPeriod": "2023-02-01T00:00:00.000Z",
                    "charge": 1741.41
                  },
                  {
                    "billingPeriod": "2023-01-01T00:00:00.000Z",
                    "charge": 1928.01
                  },
                  {
                    "billingPeriod": "2022-12-01T00:00:00.000Z",
                    "charge": 1927.55
                  }
                ],
                "serviceName": "Amazon Elastic Compute Cloud (Windows)"
              },
              {
                "spends": [
                  {
                    "billingPeriod": "2023-01-01T00:00:00.000Z",
                    "charge": 29.4
                  },
                  {
                    "billingPeriod": "2022-12-01T00:00:00.000Z",
                    "charge": 33.74
                  },
                  {
                    "billingPeriod": "2023-03-01T00:00:00.000Z",
                    "charge": 19.33
                  },
                  {
                    "billingPeriod": "2023-02-01T00:00:00.000Z",
                    "charge": 21
                  }
                ],
                "serviceName": "AWS Data Transfer"
              },
              {
                "spends": [
                  {
                    "billingPeriod": "2023-02-01T00:00:00.000Z",
                    "charge": 1541.76
                  },
                  {
                    "billingPeriod": "2023-01-01T00:00:00.000Z",
                    "charge": 1536.24
                  },
                  {
                    "billingPeriod": "2022-12-01T00:00:00.000Z",
                    "charge": 1505.76
                  },
                  {
                    "billingPeriod": "2023-03-01T00:00:00.000Z",
                    "charge": 1541.76
                  }
                ],
                "serviceName": "Amazon Elastic Block Store"
              },
              {
                "spends": [
                  {
                    "billingPeriod": "2022-01-01T00:00:00.000Z",
                    "charge": 0.26
                  }
                ],
                "serviceName": "AmazonCloudWatch"
              },
              {
                "spends": [
                  {
                    "billingPeriod": "2022-12-01T00:00:00.000Z",
                    "charge": 1.92
                  },
                  {
                    "billingPeriod": "2023-03-01T00:00:00.000Z",
                    "charge": 2.34
                  },
                  {
                    "billingPeriod": "2023-01-01T00:00:00.000Z",
                    "charge": 1.98
                  },
                  {
                    "billingPeriod": "2023-02-01T00:00:00.000Z",
                    "charge": 2.12
                  }
                ],
                "serviceName": "Amazon GuardDuty"
              },
              {
                "spends": [
                  {
                    "billingPeriod": "2022-12-01T00:00:00.000Z",
                    "charge": 0
                  },
                  {
                    "billingPeriod": "2023-02-01T00:00:00.000Z",
                    "charge": 0
                  },
                  {
                    "billingPeriod": "2023-01-01T00:00:00.000Z",
                    "charge": 0
                  },
                  {
                    "billingPeriod": "2023-03-01T00:00:00.000Z",
                    "charge": 0
                  }
                ],
                "serviceName": "AWS Secrets Manager"
              },
              {
                "spends": [
                  {
                    "billingPeriod": "2021-10-01T00:00:00.000Z",
                    "charge": 48.36
                  }
                ],
                "serviceName": "Amazon Virtual Private Cloud"
              }
            ]
          }
        }
      ]
    }
  }
};
