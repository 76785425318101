import ReactDOM from 'react-dom/client';
import "@awsui/global-styles/index.css";
import App from "./App";

import { Amplify } from "aws-amplify";
import awsExports from "./aws-exports";
import { isDevelopment, isProduction, isStaging } from 'resources/util';

console.log(process.env);

console.log("Default awsExports: ", awsExports);

let updatedAwsExports = {};

if (isDevelopment()) {
  // Amplify.Logger.LOG_LEVEL = "DEBUG";
  updatedAwsExports = {
    ...awsExports,
    aws_user_pools_id: "us-east-1_Bdm58OpAX",
    aws_cognito_identity_pool_id: "us-east-1:d091810c-b2e7-4a14-949e-cae977f4a75a",
    aws_user_pools_web_client_id: "43tol74hn2jh7pcbrt62g0j22e",
    oauth: {
      ...awsExports.oauth,
      domain: "whatsnew-midway-auth-dev.auth.us-east-1.amazoncognito.com",
      redirectSignIn: "http://localhost:3000/",
      redirectSignOut: "http://localhost:3000/",
    },
  };
}

if (isProduction()) {
  updatedAwsExports = {
    ...awsExports,
    aws_user_pools_id: "us-east-1_apmQ3UsGb",
    aws_cognito_identity_pool_id: "us-east-1:7dbba1df-7c95-4971-8054-c5867b0c10d8",
    aws_user_pools_web_client_id: "43hmumg29hka8uuqfu6hdq1bnc",
    oauth: {
      ...awsExports.oauth,
      domain: "whatsnew-midway-auth-prod.auth.us-east-1.amazoncognito.com",
      redirectSignIn: "https://whatsnew.sa.aws.dev/",
      redirectSignOut: "https://whatsnew.sa.aws.dev/",
    },
  };
}

if (isStaging()) {
  // Amplify.Logger.LOG_LEVEL = "DEBUG";
  updatedAwsExports = {
    ...awsExports,
    aws_user_pools_id: "us-east-1_Bdm58OpAX",
    aws_cognito_identity_pool_id: "us-east-1:d091810c-b2e7-4a14-949e-cae977f4a75a",
    aws_user_pools_web_client_id: "43tol74hn2jh7pcbrt62g0j22e",
    oauth: {
      ...awsExports.oauth,
      domain: "whatsnew-midway-auth-dev.auth.us-east-1.amazoncognito.com",
      redirectSignIn: "https://mrkrchm.people.aws.dev/",
      redirectSignOut: "https://mrkrchm.people.aws.dev/",
    },
  };
}

console.log("Updated awsExports: ", updatedAwsExports);

Amplify.configure(updatedAwsExports);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
    <App />
);
