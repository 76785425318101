import { useEffect, useState } from "react";
import Alert from "@awsui/components-react/alert";
import Box from "@awsui/components-react/box";
import Button from "@awsui/components-react/button";
import CodeEditor from "@awsui/components-react/code-editor";
import Container from "@awsui/components-react/container";
import Flashbar from "@awsui/components-react/flashbar";
import FormField from "@awsui/components-react/form-field";
import Header from "@awsui/components-react/header";
import Input from "@awsui/components-react/input";
import Modal from "@awsui/components-react/modal";
import SpaceBetween from "@awsui/components-react/space-between";

import { useHistory } from "react-router-dom";
import moment from "moment";
import { render } from "react-dom";
import AceEditor from "react-ace";

import "ace-builds/src-noconflict/mode-handlebars";
import "ace-builds/src-noconflict/theme-github";
import "ace-builds/src-noconflict/ext-language_tools";
import { postUserAction, getTemplate, updateTemplate } from "../resources/api";

function EditTemplate() {
  const history = useHistory();
  const [alertVisible, setAlertVisible] = useState(true);
  const [errorVisible, setErrorVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [cancelModalVisible, setCancelModalVisible] = useState(false);
  const [successAlertVisible, setSuccessAlertVisible] = useState(false);
  const [ace, setAce] = useState(undefined);
  const [content, setContent] = useState("// Write your code here");
  const [title, setTitle] = useState("");
  const [language, setLanguage] = useState("handlebars");
  const [loading, setLoading] = useState(true);
  const [preferences, setPreferences] = useState(undefined);

  const id = window.location.pathname.substring(
    window.location.pathname.lastIndexOf("/") + 1
  );

  const i18nStrings = {
    loadingState: "Loading code editor",
    errorState: "There was an error loading the code editor.",
    errorStateRecovery: "Retry",

    editorGroupAriaLabel: "Code editor",
    statusBarGroupAriaLabel: "Status bar",

    cursorPosition: (row, column) => `Ln ${row}, Col ${column}`,
    errorsTab: "Errors",
    warningsTab: "Warnings",
    preferencesButtonAriaLabel: "Preferences",

    paneCloseButtonAriaLabel: "Close",

    preferencesModalHeader: "Preferences",
    preferencesModalCancel: "Cancel",
    preferencesModalConfirm: "Confirm",
    preferencesModalWrapLines: "Wrap lines",
    preferencesModalTheme: "Theme",
    preferencesModalLightThemes: "Light themes",
    preferencesModalDarkThemes: "Dark themes",
  };

  useEffect(() => {
    setLoading(true);
    import("ace-builds")
      .then((ace) =>
        import("ace-builds/webpack-resolver")
          .then(() => {
            ace.config.set("useStrictCSP", false);
            ace.config.set("loadWorkerFromBlob", false);
            setAce(ace);
          })
          .catch(() => setLoading(false))
      )
      .catch(() => setLoading(false));

    getTemplate(id)
      .then((t) => {
        console.log("got template: " + JSON.stringify(t));
        console.log(`title: ${t.Title}`);
        console.log(`content: ${t.Content}`);
        setContent(t.Content.trim());
        setTitle(t.Title);
        setLoading(false);
        postUserAction("viewTemplate");
      })
      .catch(console.error);
    setLoading(false);
  }, [id]);

  const onSave = async () => {
    setSuccessAlertVisible(false);
    setErrorVisible(false);

    if (title.trim() === "") {
      setErrorVisible(true);
      setErrorMessage("Title is required");
      return;
    }

    if (content.trim() === "") {
      setErrorVisible(true);
      setErrorMessage("Template content is required");
      return;
    }

    const t = {
      Title: title,
      Content: content,
    };
    console.log(JSON.stringify(t));
    await postUserAction("updateTemplate");
    try {
      await updateTemplate(id, t);
      setSuccessAlertVisible(true);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Container header={<Header variant="h1">Edit template</Header>}>
      <SpaceBetween size="s">
        {successAlertVisible && (
          <Flashbar
            items={[
              {
                type: "success",
                content: "Your template has been updated successfully.",
                id: "message_1",
              },
            ]}
          />
        )}
        {errorVisible && (
          <Flashbar
            items={[
              {
                type: "error",
                content: errorMessage,
                id: "message_1",
              },
            ]}
          />
        )}
        <FormField label="Title">
          <Input
            value={title}
            onChange={(event) => setTitle(event.detail.value)}
          />
        </FormField>

        <CodeEditor
          ace={ace}
          language={language}
          value={content}
          preferences={preferences}
          onChange={(e) => setContent(e.detail.value)}
          onPreferencesChange={(e) => setPreferences(e.detail)}
          loading={loading}
          i18nStrings={i18nStrings}
          // editorContentHeight={15}
        />
        <SpaceBetween direction="horizontal" size="xs">
          <Button onClick={() => setCancelModalVisible(true)}>Cancel</Button>
          <Button variant="primary" onClick={onSave}>
            Save
          </Button>
        </SpaceBetween>
        <Alert
          header="Template expressions"
          onDismiss={() => setAlertVisible(false)}
          visible={alertVisible}
          dismissible
          dismissAriaLabel="Close alert"
        >
          <p>
            <strong>Handlebars</strong> expressions are the basic unit of a
            template. Handlebars expressions are a variable name enclosed by
            double curly braces, for example <code>{"{{ first_name }}"}</code>.
            Supported variables:
          </p>
          <ul>
            <li>
              Account name: <code>{"{{ account }}"}</code>
            </li>
            <li>
              Your first name: <code>{"{{ first_name }}"}</code>
            </li>
            <li>
              Your full name: <code>{"{{ full_name }}"}</code>
            </li>
            <li>
              Account's top 5 service list: <code>{"{{ services }}"}</code>
            </li>
          </ul>
        </Alert>
      </SpaceBetween>
      <Modal
        header="Cancel changes?"
        onDismiss={() => setCancelModalVisible(false)}
        visible={cancelModalVisible}
        closeAriaLabel="Close modal"
        footer={
          <Box float="right">
            <SpaceBetween direction="horizontal" size="xs">
              <Button
                variant="link"
                onClick={() => setCancelModalVisible(false)}
              >
                Continue editing
              </Button>
              <Button
                variant="primary"
                onClick={() => history.push("/templates")}
              >
                Cancel changes
              </Button>
            </SpaceBetween>
          </Box>
        }
      >
        This might make you sad.
      </Modal>
    </Container>
  );
}

export default EditTemplate;
