import { Auth } from 'aws-amplify';

function getCurrentUserInfo() {
  return Auth.currentUserInfo()
    .then((user) => user)
    .catch((err) => {
      console.log('Not signed in');
    });
}

function getFullName() {
  if (process.env.NODE_ENV === 'development') {
    return Promise.resolve('Developer');
  }

  return getCurrentUserInfo().then((user) =>
    // console.log("user", user);
    `${user.attributes.given_name} ${user.attributes.family_name}`);
}

function getAlias() {
  if (process.env.NODE_ENV === 'development') {
    return Promise.resolve('developer');
  }
  return getCurrentUserInfo().then((user) =>
    // console.log("getAlias", JSON.stringify(user));
    // console.log("alias", user.attributes.email.split("@")[0]);
    user.attributes.email.split('@')[0]);
}

function getUser() {
  return Auth.currentAuthenticatedUser()
    .then((user) => user)
    .catch((err) => {
      console.log('Not signed in');
    });
}

function getToken() {
  return Auth.currentSession().then((session) => session.getAccessToken().getJwtToken());
}

export async function getAwsCredentials() {
  const credentials = await Auth.currentCredentials();
  console.log(`Credentials: ${JSON.stringify(credentials)}`);
  return credentials;
}

export {
  getCurrentUserInfo, getFullName, getAlias, getUser, getToken,
};
