import { useEffect, useState } from "react";

import Alert from "@awsui/components-react/alert";
import Button from "@awsui/components-react/button";
import CodeEditor from "@awsui/components-react/code-editor";
import Container from "@awsui/components-react/container";
import Flashbar from "@awsui/components-react/flashbar";
import FormField from "@awsui/components-react/form-field";
import Input from "@awsui/components-react/input";
import Header from "@awsui/components-react/header";
import SpaceBetween from "@awsui/components-react/space-between";

import moment from "moment";
import { render } from "react-dom";
import AceEditor from "react-ace";

import "ace-builds/src-noconflict/mode-handlebars";
import "ace-builds/src-noconflict/theme-github";
import "ace-builds/src-noconflict/ext-language_tools";
import { createTemplate, postUserAction } from "../resources/api";

function CreateTemplate() {
  const [alertVisible, setAlertVisible] = useState(true);
  const [errorVisible, setErrorVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successAlertVisible, setSuccessAlertVisible] = useState(false);
  const [ace, setAce] = useState(undefined);
  const [content, setContent] = useState(
    `
  Write your template code here. You may use HTML and Handlebars syntax.

  Here's an example:

  <p>
  <b>Subject:</b> Re: {{ first_name }}, your AWS Solutions Architect — Updates to your AWS Services
  </p>
  <p>Hi &lt;INSERT CUSTOMER NAME&gt;,</p>
  <p>
    My name is {{ full_name }} and I am the AWS Senior Solutions Architect 
    supporting {{ account }}. I'm reaching out as I see your workloads 
    primarily use {{ services }}. Based on this usage, I'm sharing some recent 
    AWS updates that may directly benefit you, and I would love to meet to 
    review them. Customers with whom I have conducted these reviews have 
    achieved <strong>improved performance, lower costs, and quicker time 
    to innovation.</strong>
  </p>
  <p>
    Are you available on &lt;INSERT DATE(S)&gt; to discuss further? You may
    also be able to receive AWS credits upon completion of a deep dive
    consultation with me.
  </p>
  <p>
    Regards,
    <br />
    {{ full_name }}
    <br />
    Solutions Architect
    <br />
    Amazon Web Services
  </p>`
  );
  const [title, setTitle] = useState("Your template title here");
  const [language, setLanguage] = useState("handlebars");
  const [loading, setLoading] = useState(true);
  const [preferences, setPreferences] = useState(undefined);

  const id = window.location.pathname.substring(
    window.location.pathname.lastIndexOf("/") + 1
  );

  const i18nStrings = {
    loadingState: "Loading code editor",
    errorState: "There was an error loading the code editor.",
    errorStateRecovery: "Retry",

    editorGroupAriaLabel: "Code editor",
    statusBarGroupAriaLabel: "Status bar",

    cursorPosition: (row, column) => `Ln ${row}, Col ${column}`,
    errorsTab: "Errors",
    warningsTab: "Warnings",
    preferencesButtonAriaLabel: "Preferences",

    paneCloseButtonAriaLabel: "Close",

    preferencesModalHeader: "Preferences",
    preferencesModalCancel: "Cancel",
    preferencesModalConfirm: "Confirm",
    preferencesModalWrapLines: "Wrap lines",
    preferencesModalTheme: "Theme",
    preferencesModalLightThemes: "Light themes",
    preferencesModalDarkThemes: "Dark themes",
  };

  useEffect(() => {
    import("ace-builds")
      .then((ace) =>
        import("ace-builds/webpack-resolver")
          .then(() => {
            ace.config.set("useStrictCSP", false);
            ace.config.set("loadWorkerFromBlob", false);
            setAce(ace);
            setLoading(false);
          })
          .catch(() => setLoading(false))
      )
      .catch(() => setLoading(false));
  }, [id]);

  const onSave = async () => {
    setSuccessAlertVisible(false);
    setErrorVisible(false);

    if (title.trim() === "") {
      setErrorVisible(true);
      setErrorMessage("Title is required");
      return;
    }

    if (content.trim() === "") {
      setErrorVisible(true);
      setErrorMessage("Template content is required");
      return;
    }

    const t = {
      Title: title,
      Content: content,
    };
    console.log(JSON.stringify(t));
    await postUserAction("createTemplate");
    try {
      await createTemplate(t);
      setSuccessAlertVisible(true);
    } catch (e) {
      console.error(e);
    }
  };

  const onCancel = async () => {
    this.nextPath("/templates");
  };

  return (
    <Container header={<Header variant="h1">Create template</Header>}>
      <SpaceBetween size="s">
        {successAlertVisible && (
          <Flashbar
            items={[
              {
                type: "success",
                content: "Your template has been created successfully.",
                id: "message_1",
              },
            ]}
          />
        )}
        {errorVisible && (
          <Flashbar
            items={[
              {
                type: "error",
                content: errorMessage,
                id: "message_1",
              },
            ]}
          />
        )}
        <FormField label="Title">
          <Input
            value={title}
            onChange={(event) => setTitle(event.detail.value)}
          />
        </FormField>

        <CodeEditor
          ace={ace}
          language={language}
          value={content}
          preferences={preferences}
          onChange={(e) => setContent(e.detail.value)}
          onPreferencesChange={(e) => setPreferences(e.detail)}
          loading={loading}
          i18nStrings={i18nStrings}
          // editorContentHeight={15}
        />
        <SpaceBetween direction="horizontal" size="xs">
          <Button onClick={onCancel}>Cancel</Button>
          <Button variant="primary" onClick={onSave}>
            Save
          </Button>
        </SpaceBetween>
        <Alert
          header="Template expressions"
          onDismiss={() => setAlertVisible(false)}
          visible={alertVisible}
          dismissible
          dismissAriaLabel="Close alert"
        >
          <p>
            <strong>Handlebars</strong> expressions are the basic unit of a
            template. Handlebars expressions are a variable name enclosed by
            double curly braces, for example <code>{"{{ first_name }}"}</code>.
            Supported variables:
          </p>
          <ul>
            <li>
              Account name: <code>{"{{ account }}"}</code>
            </li>
            <li>
              Your first name: <code>{"{{ first_name }}"}</code>
            </li>
            <li>
              Your full name: <code>{"{{ full_name }}"}</code>
            </li>
            <li>
              Account's top 5 service list: <code>{"{{ services }}"}</code>
            </li>
          </ul>
        </Alert>
      </SpaceBetween>
    </Container>
  );
}

export default CreateTemplate;
