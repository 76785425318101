import { useCallback, useEffect, useState } from 'react';
import {
  Button,
  Container,
  Header,
  Popover,
  SpaceBetween,
  FormField,
  Select,
} from '@awsui/components-react';
import StatusIndicator from "@awsui/components-react/status-indicator";
import { postUserAction } from '../resources/api';
import { applyTemplate } from '../resources/template';
import Articles from './Articles';

import '../styles/customer.scss';

function toCommaSeparatedList(serviceList) {
  // console.log("toCommaSeparatedList: ", serviceList);
  const services = [...serviceList];
  const last = services.pop();
  const result = `${services.join(', ')}, and ${last}`;
  return result;
}

function htmlID(name) {
  return `customer-${name.replace(/[\W_]+/g, '')}`;
}

function selectText(node) {
  if (document.body.createTextRange) {
    const range = document.body.createTextRange();
    range.moveToElementText(node);
    range.select();
  } else if (window.getSelection) {
    const selection = window.getSelection();
    const range = document.createRange();
    range.selectNodeContents(node);
    selection.removeAllRanges();
    selection.addRange(range);
  } else {
    console.warn('Could not select text in node: Unsupported browser.');
  }
}

function copyToClipboard(elementId) {
  console.log(`Copying from ${elementId}`);
  const articleId = document.getElementById(elementId);
  selectText(articleId);
  document.execCommand('copy');
  document.getSelection().removeAllRanges();
}

export default function CustomerDetail(props) {
  // console.log("Customer props: ", JSON.stringify(props));
  // console.log("Customer props:");
  // console.log(props);
  const { account } = props;
  const { articles } = props;
  // const { services } = props;
  // console.log(
  //   `${account} has ${services.length} services: ${JSON.stringify(services)}`
  // );
  // const servicesList = toCommaSeparatedList(props.services);
  // console.log(`comma-split services: ${servicesList}`);
  const { full_name } = props;
  const first_name = props.full_name.split(' ')[0];
  const { templates } = props;

  const templateOptions = templates.map((t) => {
    console.log('template: ', t);

    // TODO figure out why some `title` fields are capitalized vs. lowercase

    if (t.title) {
      return {
        label: t.title,
        value: t.SK,
      };
    }
    return {
      label: t.Title,
      value: t.SK,
    };
  });

  const [selectedTemplate, setSelectedTemplate] = useState({
    label: 'System Default',
    value: 'template#0',
  });

  const [emailBody, setEmailBody] = useState('');

  const fetchData = useCallback(async()=> {
    const td = { ...props };
    td.first_name = first_name;
    td.services = toCommaSeparatedList(td.services);
    const templateResult = await applyTemplate('template#0', td);
    setEmailBody(templateResult);
  }, [first_name, props])

  useEffect(() => {
    fetchData()
  }, [fetchData]);

  return (
    <Container
      header={(
        <Header
          variant="h1"
          actions={(
            <SpaceBetween direction="horizontal" size="xs">
              <Popover
                dismissButton={false}
                position="top"
                size="small"
                triggerType="custom"
                content={(
                  <StatusIndicator type="success">
                    Copied to clipboard!
                  </StatusIndicator>
                )}
              >
                <Button
                  variant="primary"
                  iconName="copy"
                  data-customer={account}
                  data-clipboard-target={htmlID(account)}
                  className="btn-clipboard"
                  onClick={async () => {
                    copyToClipboard(htmlID(account));
                    await postUserAction('copy-to-clipboard');
                  }}
                >
                  Copy
                </Button>
              </Popover>
            </SpaceBetween>
          )}
        >
          {account}
        </Header>
      )}
      footer={<a href="#top">Back to top of page</a>}
    >
      <FormField label="Template">
        <Select
          selectedOption={selectedTemplate}
          onChange={async ({ detail }) => {
            console.log('Selected template: ', JSON.stringify(detail));
            setSelectedTemplate(detail.selectedOption);
            const templateId = detail.selectedOption.value; // e.g. template#1659364583197,
            const templateData = {
              account,
              articles,
              services: toCommaSeparatedList(props.services),
              full_name,
              first_name,
            };
            const templateResult = await applyTemplate(
              templateId,
              templateData,
            );
            // console.log("Template result: ", templateResult);
            setEmailBody(templateResult);
          }}
          options={templateOptions}
          selectedAriaLabel="Selected"
        />
      </FormField>
      <div id={htmlID(account)}>
        
        <div dangerouslySetInnerHTML={{ __html: emailBody }} />
        <Articles articles={articles} />
      </div>
    </Container>
  );
}
