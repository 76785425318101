import React from "react";
import { withRouter } from "react-router-dom";
import SideNavigation from "@awsui/components-react/side-navigation";

const items = [
  // {
  //   type: "section",
  //   text: "Customers",
  //   items: [{ type: "link", text: "My territories", href: "/territories" }],
  // },
  {
    type: "section",
    text: "Reports",
    items: [
      { type: "link", text: "Create Report", href: "/create" },
      { type: "link", text: "List Reports", href: "/reports" },
    ],
  },
  {
    type: "divider",
  },
  {
    type: "section",
    text: "Templates",
    items: [
      { type: "link", text: "Create Template", href: "/createTemplate" },
      { type: "link", text: "List Templates", href: "/templates" },
    ],
  },
  {
    type: "divider",
  },
  {
    type: "link",
    text: "About What's New",
    href: "/about",
  },
];

class ServiceNavigation extends React.Component {
  // If the provided link is empty, do not redirect pages
  onFollowHandler(event) {
    console.log("onFollowHandler", event);
    event.preventDefault();
    if (event.detail.href) {
      this.props.history.push(event.detail.href);
    }
  }

  render() {
    return (
      <SideNavigation
        header={{ text: "What's New", href: "/" }}
        items={items}
        // activeHref={`#${this.props.location.pathname}`}
        onFollow={this.onFollowHandler.bind(this)}
      />
    );
  }
}

export default withRouter(ServiceNavigation);
