import { Auth } from 'aws-amplify';
import axios from 'axios';
import jwt from 'jsonwebtoken';
import { isDevelopment, isStaging } from './util';

let APIGW_ENDPOINT = '';

if (isDevelopment()) {
  APIGW_ENDPOINT = 'https://h7p5nvbuhb.execute-api.us-east-1.amazonaws.com/v1';
  console.log(`Using DEV endpoint: ${APIGW_ENDPOINT}`);
} else if (isStaging()) {
  APIGW_ENDPOINT = process.env.REACT_APP_APIGW_ENDPOINT;
  console.log(`Using STAGING endpoint: ${APIGW_ENDPOINT}`);
} else {
  APIGW_ENDPOINT = 'https://qz5tvbpl2h.execute-api.us-east-1.amazonaws.com/v1';
  console.log(`Using PROD endpoint: ${APIGW_ENDPOINT}`);
}

// if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
//   // development build code
//   APIGW_ENDPOINT = "https://7uq6ajbr9b.execute-api.us-east-1.amazonaws.com/v1";
//   console.log(`Using DEV endpoint: ${APIGW_ENDPOINT}`);
// } else if (process.env.REACT_APP_ENV === "staging") {
//   // staging build code (same as dev)
//   APIGW_ENDPOINT = process.env.REACT_APP_APIGW_ENDPOINT;
//   console.log(`Using STAGING endpoint: ${APIGW_ENDPOINT}`);
// } else {
//   // production build code
//   APIGW_ENDPOINT = "https://qz5tvbpl2h.execute-api.us-east-1.amazonaws.com/v1";
//   console.log(`Using PROD endpoint: ${APIGW_ENDPOINT}`);
// }

// Default config options
const defaultOptions = {
  baseURL: APIGW_ENDPOINT,
};

// Create instance
const instance = axios.create(defaultOptions);

// Add Authorization header to all axios calls
instance.interceptors.request.use(async (config) => {
  // local development
  if (process.env.NODE_ENV === 'development') {
    console.log('Using local auth token');
    const developerToken = jwt.sign(
      {
        sub: 'deadbeef-abcd-1234-abcd-123456789012',
        given_name: 'Mark',
        family_name: 'Richman',
        email: 'mrkrchm@amazon.com',
        identities: [
          {
            userId: 'mrkrchm',
          },
        ],
      },
      'my_secret_key',
      {
        keyid: 'S4CusA5InezmO3BEbJV9XJ4v4gUvXsMJhVPf/HoJndw=',
        expiresIn: 60 * 60,
      },
    );
    console.log(`developerToken: ${developerToken}`);
    // eslint-disable-next-line no-param-reassign
    config.headers.Authorization = developerToken;
    return config;
  }
  const user = await Auth.currentAuthenticatedUser();
  console.log(`Using AWS auth token for user ${user}`);
  const token = user.signInUserSession.idToken.jwtToken;
  console.log(`token: ${token}`);
  // eslint-disable-next-line no-param-reassign
  config.headers.Authorization = token;
  return config;
});

export const listReports = async () => {
  const resp = await instance.get('/reports');
  console.log(resp.data);
  return resp.data;
};

export const getPresignedUrlText = async (id) => {
  console.log(`Getting presigned URL for report: ${id}`);
  const resp = await instance.get(`/report?SK=${id}`);
  console.log(resp.data);
  return resp.data;
};

export const viewReport = async (id) => {
  const url = await getPresignedUrlText(id);
  console.log(`viewReport presigned URL: ${url}`);
  const resp = await axios.get(url); // don't use custom axios instance
  const json = await resp.data;
  console.log(json);
  return json;
};

export const getUploadUrl = async () => {
  const resp = await instance.get('/getUploadUrl');
  console.log(resp.data);
  return resp.data;
};

export const getDownloadUrl = async () => {
  const resp = await instance.get('/getDownloadUrl');
  console.log(resp.data);
  return resp.data;
};

export const postUserAction = async (action) => {
  console.log(`Posting user action: ${action}`);
  const resp = await instance.post('/useraction', { action });
  console.log(resp.data);
  return resp.data;
};

export const listTemplates = async () => {
  console.log('Listing templates');
  const resp = await instance.get('/templates');
  console.log(resp.data);
  return resp.data;
};

export const getTemplate = async (id) => {
  console.log(`Getting template: ${id}`);
  const resp = await instance.get(`/template/${id}`);
  const json = await resp.data;
  console.log(json);
  return json;
};
export const createTemplate = async (template) => {
  console.log(`Creating template with: ${{ template }}`);
  const resp = await instance.post('/templates', template);
  console.log(resp.data);
  return resp.data;
};

export const updateTemplate = async (id, template) => {
  console.log(`Updating template: ${id} with: ${{ template }}`);
  const resp = await instance.put(`/template/${id}`, template);
  console.log(resp.data);
  return resp.data;
};

export const getArticlesForServices = async (serviceNames) => {
  // message looks like ["Amazon Elastic Compute Cloud (Linux)","Amazon Elastic Compute Cloud (Windows)","Amazon Elastic Block Store","AWS Support (Business)","Amazon Elastic Compute Cloud"]
  console.log(`Getting email content for services: ${serviceNames}`);
  const resp = await instance.post(`/email`, serviceNames);
  console.log(resp.data);
  return resp.data;
};

export const getConfig = async () => {
  console.log('Getting config data from API');
  // curl -s 'https://sj8icbl3bk.execute-api.us-east-1.amazonaws.com/prod/getconfig?system=whatsnew' | jq -r '.data.suds_endpoint'
  const resp = await axios.get(
    'https://sj8icbl3bk.execute-api.us-east-1.amazonaws.com/prod/getconfig?system=whatsnew',
  );
  console.log(resp.data);
  return resp.data;
};
