import { useEffect, useState } from "react";
import Box from "@awsui/components-react/box";
import Button from "@awsui/components-react/button";
import Container from "@awsui/components-react/container";
import Header from "@awsui/components-react/header";
import SpaceBetween from "@awsui/components-react/space-between";
import Table from "@awsui/components-react/table";

import moment from "moment";
import { listReports, postUserAction } from "../resources/api";
import StatusIndicator from "@awsui/components-react/status-indicator";

const columnDefinitions = [
  {
    id: "PK",
    header: "Generated By",
    cell: (item) => item.PK,
    minWidth: 200,
  },
  {
    id: "SK",
    header: "Timestamp",
    sortingField: "Timestamp",
    cell: (item) => {
      const dt = parseInt(item.SK.split("-")[1]);
      return moment(dt).fromNow();
      // var dateString = moment.unix(dt / 1000).format("YYYY-MM-DD HH:mm:ss");
      // return dateString;
    },
    width: 200,
  },
  {
    id: "status",
    header: "Status",
    cell: (item) => {
      if (item.Status === "started") {
        return (
          <>
            <StatusIndicator type="in-progress">In progress</StatusIndicator>
          </>
        );
      } else if (item.Status === "finished") {
        return (
          <>
            <StatusIndicator>Success</StatusIndicator>
          </>
        );
      } else {
        return (
          <>
            <StatusIndicator type="error">Error</StatusIndicator>
          </>
        );
      }
    },
    minWidth: 80,
  },
  {
    id: "message",
    header: "Message",
    cell: (item) => item.Message,
    minWidth: 200,
  },
  {
    id: "actions",
    cell: (item) => (
      <Button
        variant="primary"
        // ariaLabel={item.Status?.Value}
        {...(item.Status !== "finished" ? { disabled: true } : {})}
        formAction="none"
        href={`/report?id=${item.SK}`}
        iconAlign="undefined"
        iconName="undefined"
      >
        View
      </Button>
    ),
    header: "Actions",
    minWidth: 100,
  },
];

const tableEmpty = (
  <Box textAlign="center" color="inherit">
    <Box variant="p" color="inherit">
      No documents to display.
    </Box>
  </Box>
);

function ListReports() {
  const [data, setData] = useState([]);
  const [tableLoading, setTableLoading] = useState(true);

  useEffect(() => {
    listReports()
      .then((data) => {
        console.log(data);
        setData(data);
        setTableLoading(false);
        postUserAction("listReports");
      })
      .catch(console.error);
  }, []);

  return (
    <Container header={<Header variant="h1">Reports</Header>}>
      <SpaceBetween direction="vertical" size="s">
        <Button
          iconName="refresh"
          onClick={() => {
            setTableLoading(true);
            listReports()
              .then((data) => {
                setData(data);
                setTableLoading(false);
              })
              .catch(console.error);
          }}
        >
          Refresh
        </Button>
        <Table
          loadingText="Loading reports..."
          columnDefinitions={columnDefinitions}
          items={data}
          empty={tableEmpty}
          loading={tableLoading}
          resizableColumns={true}
        />
      </SpaceBetween>
    </Container>
  );
}

export default ListReports;
