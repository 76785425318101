import { getTemplate } from "./api";
import Handlebars from "handlebars";

const defaultTemplate = `
  <p>
    <b>Subject:</b> Re: {{ first_name }}, your AWS Solutions Architect — Updates to your AWS Services
  </p>
  <p>Hi &lt;INSERT CUSTOMER NAME&gt;,</p>
  <p>
    My name is {{ full_name }} and I am the AWS Senior Solutions Architect 
    supporting {{ account }}. I'm reaching out as I see your workloads 
    primarily use {{ services }}. Based on this usage, I'm sharing some recent 
    AWS updates that may directly benefit you, and I would love to meet to 
    review them. Customers with whom I have conducted these reviews have 
    achieved <strong>improved performance, lower costs, and quicker time 
    to innovation.</strong>
  </p>
  <p>
    Are you available on &lt;INSERT DATE(S)&gt; to discuss further? You may
    also be able to receive AWS credits upon completion of a deep dive
    consultation with me.
  </p>
  <p>
    Regards,
    <br />
    {{ full_name }}
    <br />
    Solutions Architect
    <br />
    Amazon Web Services
  </p>
`;

// const defaultTemplateData = {
//   PK: {
//     Value: "admin",
//   },
//   SK: {
//     Value: "template#0",
//   },
//   Content: {
//     Value: defaultTemplate,
//   },
//   Title: {
//     Value: "System default",
//   },
//   Type: {
//     Value: "template",
//   },
// };

const defaultTemplateData = {
  PK: "admin",
  SK: "template#0",
  Content: defaultTemplate,
  Title: "System default",
  Type: "template",
};

const applyTemplate = async (templateId, data) => {
  const id = templateId.replace("template#", "");
  console.log(`Getting template id: ${id}`);
  try {
    let t;
    if (id === "0") {
      t = defaultTemplateData;
    } else {
      t = await getTemplate(id);
    }

    console.log("Template data: ", JSON.stringify(t));

    //TODO understand the case sensitivity
    const source = t.Content;
    console.log("Handlebars: " + source);
    const template = Handlebars.compile(source);
    console.log("Input data to template: " + { data });
    console.log(data);
    const result = await template(data);
    // Usage: <div dangerouslySetInnerHTML={{ __html: template(data) }} />
    return result;
  } catch (error) {
    console.error(error);
  }
};

export { applyTemplate, defaultTemplateData };
