import { useCallback, useEffect, useState } from "react";
import Alert from "@awsui/components-react/alert";
import Box from "@awsui/components-react/box";
import Container from "@awsui/components-react/container";
import ExpandableSection from "@awsui/components-react/expandable-section";
import Header from "@awsui/components-react/header";
import SpaceBetween from "@awsui/components-react/space-between";
import Spinner from "@awsui/components-react/spinner";

import moment from "moment";
import { postUserAction, viewReport, listTemplates } from "../resources/api";
import CustomerDetail from "../components/CustomerDetail";
import { getFullName } from "../resources/auth";
import { defaultTemplateData } from "../resources/template";

function ViewReport() {
  const queryParams = new URLSearchParams(window.location.search);
  const [data, setData] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [fullName, setFullName] = useState("");
  const [templates, setTemplates] = useState([]);
  const id = queryParams.get("id");

  const fetchData = useCallback(async () => {
    const name = await getFullName();
    setFullName(name);

    const t = await listTemplates();
    // const defaultTemplateData = getDefaultTemplateData();
    console.log("defaultTemplate: ", defaultTemplateData);
    t.unshift(defaultTemplateData);
    setTemplates(t);

    // console.log("data", data);
    // Fetch presigned URL to report data in S3
    const reportData = await viewReport(id);
    setData(reportData);
    setCustomers(reportData.Customers);
    await postUserAction("viewReport");
  }, [id])

  useEffect(() => {
    fetchData()
  }, [fetchData]);

  return (
    <Container
      header={
        <Header variant="h1" description={`${id}`}>
          {data?.CreatedOn
            ? "Created " + moment(data.CreatedOn).fromNow()
            : "Loading..."}
        </Header>
      }
    >
      <a id="top" />
      <SpaceBetween size="l">
        <Alert>
          Customers are sorted alphabetically, case-sensitive. Scroll down or
          search to find your customer. Use the <b>Copy</b> button to copy the
          content to your clipboard.
        </Alert>
        {customers.length === 0 ? (
          <Box margin="xl" padding="xl" textAlign="center">
            <span aria-label="loading">
              Fetching report data... <br />
              <Spinner size="big" />
            </span>
          </Box>
        ) : (
          <>
            {customers.map((c) => {
              // console.log(c);
              // c.FullName = fullName;
              // console.log("c.Services: ", c.Services);
              return (
                <ExpandableSection header={c.Name}>
                  <CustomerDetail
                    key={c.Name}
                    account={c.Name}
                    full_name={fullName}
                    articles={c.Articles}
                    templates={templates}
                    services={c.Services}
                  />
                </ExpandableSection>
              );
            })}
          </>
        )}
      </SpaceBetween>
    </Container>
  );
}

export default ViewReport;
