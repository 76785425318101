import { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Container,
  Header,
  Table,
  Link
} from '@awsui/components-react';

import { getMyPersonId, getMyTerritories } from '../resources/suds';

const columnDefinitions = [
  {
    id: 'actions',
    cell: (item) => (
      <Link
        href={`/customers?territoryId=${item}`}
      >
        {item}
      </Link>
    ),
    header: 'Territory',
    minWidth: 100,
  },
];

const tableEmpty = (
  <Box textAlign="center" color="inherit">
    <Box variant="p" color="inherit">
      No data to display.
    </Box>
  </Box>
);

function Territories() {
  const [data, setData] = useState([]);
  const [tableLoading, setTableLoading] = useState(true);

  const fetchData = useCallback(async () => {
    const personId = await getMyPersonId();
    console.log(JSON.stringify(personId));
    const myTerritories = await getMyTerritories(personId);
    console.log(JSON.stringify(myTerritories));
    setData(myTerritories);
    setTableLoading(false);
  }, [])

  useEffect(() => {
    fetchData()
  }, [fetchData]);

  return (
    <Container header={<Header variant="h1">My Territories</Header>}>
        <Table
          loadingText="Loading territories..."
          columnDefinitions={columnDefinitions}
          items={data}
          empty={tableEmpty}
          loading={tableLoading}
          resizableColumns
        />
    </Container>
  );
}

export default Territories;
