import { useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Header,
  Button,
  Container,
  Flashbar,
} from "@awsui/components-react";
import SpaceBetween from "@awsui/components-react/space-between";

import axios from "axios";
import moment from "moment";
import { getUploadUrl } from "../resources/api";
import { postUserAction } from "../resources/api";
import "../styles/createReport.scss";

/**
 * Format bytes as human-readable text.
 *
 * @param bytes Number of bytes.
 * @param si True to use metric (SI) units, aka powers of 1000. False to use
 *           binary (IEC), aka powers of 1024.
 * @param dp Number of decimal places to display.
 *
 * @return Formatted string.
 */
function humanFileSize(bytes, si = false, dp = 1) {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + " B";
  }

  const units = si
    ? ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
    : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
  let u = -1;
  const r = 10 ** dp;

  do {
    bytes /= thresh;
    ++u;
  } while (
    Math.round(Math.abs(bytes) * r) / r >= thresh &&
    u < units.length - 1
  );

  return bytes.toFixed(dp) + " " + units[u];
}

function CreateReport(props) {
  const history = useHistory();
  const [selectedFile, setSelectedFile] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [flashbarVisible, setFlashbarVisible] = useState(false);
  const [items, setItems] = useState([
    {
      type: "success",
      content:
        "Your report has been submitted successfully. Please view the reports list for output.",
      action: (
        <Button onClick={() => history.push("/reports")}>View reports</Button>
      ),
      dismissible: true,
      dismissLabel: "Dismiss message",
      onDismiss: () => setItems([]),
      id: "message_1",
    },
  ]);

  const onFileChange = (event) => {
    // Update the state
    if (event.target.files[0].name.endsWith(".csv") === false) {
      alert("Please select a CSV file");
      return;
    }
    console.log("Selected file", event.target.files[0]);
    setSelectedFile(event.target.files[0]);
  };

  const onFileUpload = async () => {
    setIsUploading(true);
    postUserAction("createReport");

    let url = "";
    // Get presigned upload URL from API
    getUploadUrl()
      .then((data) => {
        url = data;
        console.log(url);
        // Upload file to S3 bucket
        const options = {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        };
        console.log(`Uploading data to S3...`);
        axios
          .put(url, selectedFile, options) // Don't wrap using FormData here
          .then((response) => {
            console.info(response);
            setIsUploading(false);
            setSelectedFile(null);
            setFlashbarVisible(true);
          })
          .catch(console.error);
      })
      .catch(console.error);
  };

  // const fileData = () => {
  //   selectedFile && (
  //     <div>
  //       <h2>File Details:</h2>
  //       <p>
  //         File Name: {selectedFile.name}
  //         <br />
  //         File Type: {selectedFile.type}
  //         <br />
  //         File Size: {humanFileSize(selectedFile.size)}
  //         <br />
  //         Last Modified:{" "}
  //         {moment(selectedFile.lastModifiedDate).format("YYYY-MM-DD HH:mm:ss")}
  //       </p>
  //     </div>
  //   );
  // };

  return (
    <Container
      header={
        <Header
          variant="h1"
          description="Upload a Tableau export (CSV) file to create a report."
        >
          Create Report
        </Header>
      }
    >
      <SpaceBetween direction="vertical" size="l">
        {flashbarVisible && <Flashbar items={items} />}
        <Button iconName="upload" className="polarisInputButton">
          <input
            className="inputButton hidden"
            type={"file"}
            accept={".csv,.tsv"}
            onChange={onFileChange}
          />
          Choose File
        </Button>
        <div>
          {selectedFile && (
            <>
              <h2>File Details:</h2>
              <p>
                File Name: {selectedFile.name}
                <br />
                File Type: {selectedFile.type}
                <br />
                File Size: {humanFileSize(selectedFile.size)}
                <br />
                Last Modified:{" "}
                {moment(selectedFile.lastModifiedDate).format(
                  "YYYY-MM-DD HH:mm:ss"
                )}
              </p>
            </>
          )}
        </div>
        <Button
          variant="primary"
          onClick={onFileUpload}
          disabled={selectedFile === null}
        >
          Upload
        </Button>
        {isUploading && (
          <Button
            formAction="none"
            iconName="upload"
            loading
            variant="link"
            wrapText={false}
          >
            Uploading...
          </Button>
        )}
      </SpaceBetween>
    </Container>
  );
}

export default CreateReport;
