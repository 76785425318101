import { Button } from "@awsui/components-react";
import AppLayout from "@awsui/components-react/app-layout";
import { Auth, Hub } from 'aws-amplify';
import Territories from 'pages/Territories';
import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Navigation from './components/Navigation';
import TopNavigation from './components/TopNavigation';
import About from './pages/About';
import CreateReport from './pages/CreateReport';
import CreateTemplate from './pages/CreateTemplate';
import Customer from './pages/Customer';
import Customers from './pages/Customers';
import EditTemplate from './pages/EditTemplate';
import Home from './pages/Home';
import ListReports from './pages/ListReports';
import ListTemplates from './pages/ListTemplates';
import ViewReport from './pages/ViewReport';
import { getToken, getUser } from './resources/auth';
import '@awsui/global-styles/index.css';
import './styles/app.scss';

function getRouter() {
  return (
    <BrowserRouter>
      <div id="h" style={{ position: 'sticky', top: 0, zIndex: 1002 }}>
        <TopNavigation />
      </div>
      <Switch>
        <Route path="/territories">
          <AppLayout
            headerSelector="#h"
            content={<Territories />}
            navigation={<Navigation />}
            toolsHide
          />
        </Route>
        <Route path="/customers">
          <AppLayout
            headerSelector="#h"
            content={<Customers />}
            navigation={<Navigation />}
            toolsHide
          />
        </Route>
        <Route path="/customer/:id">
          <AppLayout
            headerSelector="#h"
            content={<Customer />}
            navigation={<Navigation />}
            toolsHide
          />
        </Route>
        <Route path="/reports">
          <AppLayout
            headerSelector="#h"
            content={<ListReports />}
            navigation={<Navigation />}
            toolsHide
          />
        </Route>
        <Route path="/templates">
          <AppLayout
            headerSelector="#h"
            content={<ListTemplates />}
            navigation={<Navigation />}
            toolsHide
          />
        </Route>
        <Route path="/createTemplate">
          <AppLayout
            headerSelector="#h"
            content={<CreateTemplate />}
            navigation={<Navigation />}
            toolsHide
          />
        </Route>
        <Route path="/template">
          <AppLayout
            headerSelector="#h"
            content={<EditTemplate />}
            navigation={<Navigation />}
            toolsHide
          />
        </Route>
        <Route path="/create">
          <AppLayout
            headerSelector="#h"
            content={<CreateReport />}
            navigation={<Navigation />}
            toolsHide
          />
        </Route>
        <Route path="/report">
          <AppLayout
            headerSelector="#h"
            content={<ViewReport />}
            navigation={<Navigation />}
            toolsHide
          />
        </Route>
        <Route path="/about">
          <AppLayout
            headerSelector="#h"
            content={<About />}
            navigation={<Navigation />}
            toolsHide
          />
        </Route>
        <Route path="/">
          <AppLayout
            headerSelector="#h"
            content={<Home />}
            navigation={<Navigation />}
            toolsHide
          />
        </Route>
      </Switch>
    </BrowserRouter>
  );
}

// const initFeatures = () => {
//   if (!localStorage.getItem('flags')) {
//     localStorage.setItem(
//       'flags',
//       JSON.stringify([
//         {
//           name: 'templates',
//           description: 'Templates feature enabled',
//           active: false,
//         },
//       ]),
//     );
//   }
// };

function App() {
  // initFeatures();

  const [user, setUser] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [token, setToken] = useState(null);

  function validateUserSession() {
    if (process.env.NODE_ENV === 'development') {
      setUser('developer');
      return;
    }

    Auth.currentAuthenticatedUser()
      .then((currentAuthUser) => {
        console.log(
          'Auth.currentAuthenticatedUser() currentAuthUser:',
          currentAuthUser,
        );
        // grab the user session
        Auth.userSession(currentAuthUser)
          .then((session) => {
            console.log('Auth.userSession() session:', session);
            if (session.isValid()) {
              // fire user is authenticated
              console.log('user session is valid!');
              setUser(currentAuthUser);
            } else {
              // fire user is unauthenticated
              console.log('user session is not valid!');
              Auth.federatedSignIn({ provider: 'Midway' });
            }
          })
          .catch((err) => {
            console.log('Auth.userSession() err:', err);
          });
      })
      .catch((err) => {
        console.log('Auth.currentAuthenticatedUser() err:', err);
      });
  }

  useEffect(() => {
    validateUserSession();

    // listen for auth events

    Hub.listen('auth', ({ payload: { event, data } }) => {
      console.log('auth event: ', event);
      console.log('auth data: ', data);
      switch (event) {
        case 'signIn':
        case 'cognitoHostedUI':
          setToken('granting...');
          // eslint-disable-next-line no-case-declarations
          const theToken = getToken();
          setToken(theToken);
          // console.log(theToken);
          getUser().then((userData) => {
            setUser(userData);
            console.log(userData);
            console.log('attributes:', userData.attributes);
          });
          break;
        case 'signOut':
          setToken(null);
          break;
        case 'signIn_failure':
        case 'cognitoHostedUI_failure':
          console.log('Sign in failure', data);
          break;
        default:
          break;
      }
    });
  }, []);

  return (
    <div>
      {user ? (
        getRouter()
      ) : (
        <div className="centered">
          <Button
            variant="primary"
            iconAlign="right"
            iconName="lock-private"
            onClick={() => Auth.federatedSignIn({ provider: 'Midway' })}
          >
            Sign in with Midway
          </Button>
        </div>
      )}
    </div>
  );
}

export default App;
