import { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Link,
  Container,
  Header,
  Table,
} from '@awsui/components-react';

import { getMyCustomers, getMyPersonId, getMyTerritories } from '../resources/suds';

const columnDefinitions = [
  {
    id: 'actions',
    cell: (item) => (
      <Link
        href={`/customer/${item.id}`}
      >
        {item.name}
      </Link>
    ),
    header: 'Customer',
    minWidth: 100,
  },
];

const tableEmpty = (
  <Box textAlign="center" color="inherit">
    <Box variant="p" color="inherit">
      No data to display.
    </Box>
  </Box>
);

function Customers() {
  console.log("In ./src/pages/Customers.jsx");
  const queryParams = new URLSearchParams(window.location.search);
  const territoryId = queryParams.get("territoryId");
  const [data, setData] = useState([]);
  const [tableLoading, setTableLoading] = useState(true);

  const fetchData = useCallback(async () => {
    var myCustomers = await getMyCustomers(territoryId);
    myCustomers.sort((a, b) => a.name.localeCompare(b.name));
    console.log(myCustomers);
    setData(myCustomers);
    setTableLoading(false);
  }, [territoryId])

  useEffect(() => {
    fetchData()
  }, [fetchData]);

  return (
    <Container header={<Header variant="h1">My Customers</Header>}>
        <Table
          loadingText="Loading customers..."
          columnDefinitions={columnDefinitions}
          items={data}
          empty={tableEmpty}
          loading={tableLoading}
          resizableColumns
        />
    </Container>
  );
}

export default Customers;
