import Container from "@awsui/components-react/container";
import Header from "@awsui/components-react/header";
import Link from "@awsui/components-react/link";

function About() {
  return (
    <Container header={<Header variant="h1">About What's New</Header>}>
      <p>
        Questions, comments, or suggestions? Contact{" "}
        <Link href="https://phonetool.amazon.com/users/mrkrchm" target="_blank">
          Mark Richman
        </Link>{" "}
        via{" "}
        <Link href="mailto:mrkrchm@amazon.com?subject=What%27s%20New%20Feedback">
          email
        </Link>{" "}
        or{" "}
        <Link
          href="https://amzn-aws.slack.com/app_redirect?channel=@mrkrchm"
          target="_blank"
        >
          Slack
        </Link>
        . If you really ❤️ this tool,{" "}
        <Link
          href="https://accolades.corp.amazon.com/send/?user=mrkrchm"
          target="_blank"
        >
          send an accolade to Mark.
        </Link>
      </p>
    </Container>
  );
}

export default About;
