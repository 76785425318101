import {
  Box,
  Button,
  Container,
  Grid,
  Link,
  SpaceBetween,
} from "@awsui/components-react";

import "../styles/home.scss";

function Home() {
  return <Content />;
}

const Content = () => (
  <Container>
    <Box margin={{ bottom: "l" }}>
      <div className="custom-home__header">
        <Box padding={{ vertical: "xxxl", horizontal: "s" }}>
          <Grid
            gridDefinition={[
              { offset: { l: 2, xxs: 1 }, colspan: { l: 8, xxs: 10 } },
              {
                colspan: { xl: 6, l: 5, s: 6, xxs: 10 },
                offset: { l: 2, xxs: 1 },
              },
              {
                colspan: { xl: 2, l: 3, s: 4, xxs: 10 },
                offset: { s: 0, xxs: 1 },
              },
            ]}
          >
            <Box fontWeight="light" padding={{ top: "xs" }}>
              <span className="custom-home__category">
                AWS Solutions Architecture
              </span>
            </Box>

            <div className="custom-home__header-title">
              <Box
                variant="h1"
                fontWeight="heavy"
                padding="n"
                fontSize="display-l"
                color="inherit"
              >
                What's New
              </Box>
              <Box
                fontWeight="light"
                padding={{ bottom: "s" }}
                fontSize="display-l"
                color="inherit"
              >
                Customer outreach tooling for sales teams
              </Box>
              <Box variant="p" fontWeight="light">
                <span className="custom-home__header-sub-title">
                  What's New generates outreach email content with suggested
                  articles from the{" "}
                  <Link
                    href="https://aws.amazon.com/about-aws/whats-new/recent/"
                    target="_blank"
                  >
                    Recent Announcements page.
                  </Link>
                  &nbsp;Articles are selected for each customer based on their
                  top monthly spend by AWS service. The text from the report can
                  be copied into an outreach email to customers using{" "}
                  <Link
                    href="https://salesconsole.aws.dev/boost"
                    target="_blank"
                  >
                    Boost
                  </Link>{" "}
                  or Outlook.
                </span>
              </Box>
            </div>

            <Container>
              <SpaceBetween size="xl">
                <Box variant="h2" padding="n">
                  Engage customers
                </Box>
                <Button href="/territories" variant="primary">
                  Get started
                </Button>
              </SpaceBetween>
            </Container>
          </Grid>
        </Box>
      </div>
    </Box>
    <Container>
      <SpaceBetween size="xl">
        <Box variant="h2" padding="n">
          Introduction and tutorial
        </Box>
        <Box variant="h2" padding="n">
          <video
            src="/video/intro.mp4"
            width="682"
            height="384"
            controls={true}
          ></video>
        </Box>
        <Box variant="h2" padding="n">
          How it works
        </Box>
        <Box>
          <ol>
            <li>
              Begin by exporting a Tableau report for monthly spend data,
              grouped by customer.{" "}
            </li>
            <li>Upload the Tableau data to this tool.</li>
            <li>Open the generated report.</li>
            <li>
              Find the customer to whom you are interested in sending an
              outreach email. Copy the text from the report and paste it into an
              email. Edit the email to include the customer's name, your name,
              and any other relevant information. Work with your AM to ensure
              that the email is appropriate for the customer.
            </li>
          </ol>
        </Box>
        <Box variant="h2" padding="n">
          Export Tableau Data
        </Box>
        <Box>
          <ol>
            <li>
              Go to the{" "}
              <Link
                external
                externalIconAriaLabel="Opens in a new tab"
                href="https://s12d.com/TableauDataMonthly"
              >
                Data - monthly
              </Link>{" "}
              report.
            </li>
            <li>
              <p>Select Product in the Group By 2 dropdown.</p>
              <img src="/img/group-by-2.png" alt="Group By 2" height="400" />
            </li>
            <li>
              <p>Select Download from the top right menu.</p>
              <img src="/img/download.png" alt="Group By 2" />
            </li>
            <li>
              <p>Select Crosstab in the "Download" dialog.</p>
              <img src="/img/crosstab.png" alt="Group By 2" height="400" />
            </li>
            <li>
              <p>Select monthly data and CSV format. Click Download.</p>
              <img src="/img/monthly-csv.png" alt="Group By 2" height="400" />
            </li>
            <li>
              <p>
                <strong>
                  DO NOT EDIT THIS FILE IN EXCEL OR IN ANY OTHER EDITOR.
                </strong>
              </p>
            </li>
            <li>
              <p>
                Click <strong>Create Report</strong> in the left navigation menu
                of What's New
              </p>
            </li>
            <li>
              <p>
                Select the CSV file downloaded from Tableau and click{" "}
                <strong>Upload</strong>.
              </p>
            </li>
            <li>
              <p>
                Navigate to the <a href="/reports">list of reports</a>, locate
                the report you created and click <strong>View</strong>.
              </p>
            </li>
            <li>
              <p>
                Identify the customer to whom you are interested in sending an
                email and click <strong>Copy</strong>.
              </p>
            </li>
            <li>
              <p>
                Paste the email into an email client, edit to your liking and
                send.
              </p>
            </li>
          </ol>
        </Box>
      </SpaceBetween>
    </Container>
  </Container>
);

export default Home;
