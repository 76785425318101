export function isDevelopment(): boolean {
  console.log("*** running in development mode ***");
  return process.env.NODE_ENV === "development";
}

export function isStaging(): boolean {
  return process.env.REACT_APP_ENV === "staging";
}

export function isProduction(): boolean {
  return process.env.NODE_ENV === "production";
}