import {
  Box,
  Button,
  Container,
  FormField,
  Header,
  Link,
  Popover,
  Select,
  SpaceBetween,
  Spinner,
  StatusIndicator,
  Table
} from '@awsui/components-react';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from "react-router-dom";

import { getArticlesForServices, getEmailContent } from 'resources/api';
import Articles from '../components/Articles';
import { listTemplates, postUserAction } from "../resources/api";
import { getFullName } from "../resources/auth";
import { getSpendByService } from '../resources/suds';
import { applyTemplate, defaultTemplateData } from '../resources/template';

function toCommaSeparatedList(serviceList) {
  // console.log("toCommaSeparatedList: ", serviceList);
  const services = [...serviceList];
  const last = services.pop();
  const result = `${services.join(', ')}, and ${last}`;
  return result;
}

function selectText(node) {
  if (document.body.createTextRange) {
    const range = document.body.createTextRange();
    range.moveToElementText(node);
    range.select();
  } else if (window.getSelection) {
    const selection = window.getSelection();
    const range = document.createRange();
    range.selectNodeContents(node);
    selection.removeAllRanges();
    selection.addRange(range);
  } else {
    console.warn('Could not select text in node: Unsupported browser.');
  }
}

function copyToClipboard(elementId) {
  console.log(`Copying from ${elementId}`);
  const articleId = document.getElementById(elementId);
  selectText(articleId);
  document.execCommand('copy');
  document.getSelection().removeAllRanges();
}

function getTopNSpendByService(spendData, n) {
  const spendByService = spendData.data.search.nodes[0].awsci_customer.spendByService;

  const sorted = spendByService
    .map((serviceSpend) => ({
      serviceName: serviceSpend.serviceName,
      charge: serviceSpend.spends
        .sort((a, b) => new Date(b.billingPeriod) - new Date(a.billingPeriod))[0].charge
    }))
    .sort((a, b) => b.charge - a.charge)
    .slice(0, n)
    .map((item) => item.serviceName);

    console.log(JSON.stringify(sorted));
    return sorted;
}

function Customer(props) {
  const routeParams = useParams();
  console.log("routeParams", JSON.stringify(routeParams));
  const customerId = routeParams.id;
  console.log("customerId", customerId);
  const [fullName, setFullName] = useState("");
  const [firstName, setFirstName] =useState("");
  const [articles, setArticles] = useState([]);
  const [serviceNames, setServiceNames] = useState('');
  const [customerName, setCustomerName] = useState('');
  const [emailBody, setEmailBody] = useState("");
  const [tableLoading, setTableLoading] = useState(true);
  const [applyingTemplate, setApplyingTemplate] = useState(false);
  const [templates, setTemplates] = useState([]);
  
  const [selectedTemplate, setSelectedTemplate] = useState({
    label: 'System Default',
    value: 'template#0',
  });

  const templateOptions = templates.map((t) => {
    console.log('template: ', t);

    // TODO figure out why some `title` fields are capitalized vs. lowercase

    if (t.title) {
      return {
        label: t.title,
        value: t.SK,
      };
    }
    return {
      label: t.Title,
      value: t.SK,
    };
  });

  const fetchData = useCallback(async () => {
    const fullName = await getFullName();
    setFullName(fullName);
    const _firstName = fullName.split(' ')[0];
    setFirstName(_firstName);
    const spendByService = await getSpendByService(customerId);
    console.log(spendByService);
    const name = spendByService.data.search.nodes[0].name;
    setCustomerName(name);
    console.log("Customer name: ", name);
    const _serviceNames = getTopNSpendByService(spendByService, 5);
    console.log("Service names:", _serviceNames);
    setServiceNames(_serviceNames);
    const _articles = await getArticlesForServices(_serviceNames);
    setArticles(_articles);
    const t = await listTemplates();
    console.log("templates: ", {t});
    console.log("defaultTemplate: ", defaultTemplateData);
    t.unshift(defaultTemplateData); // add defaultTemplateData to dropdown
    setTemplates(t);
    const td = {};
    td.first_name = _firstName;
    td.full_name = fullName;
    td.account = name;
    td.services = toCommaSeparatedList(_serviceNames);
    const templateResult = await applyTemplate('template#0', td);
    console.log(templateResult);
    await postUserAction('view-customer');
    setEmailBody(templateResult);
    setTableLoading(false);
  }, [customerId])

  useEffect(() => {
    fetchData()
  }, [fetchData]);

  return (
    <Container header={<Header
      variant="h1"
      actions={(
        <SpaceBetween direction="horizontal" size="xs">
          <Popover
            dismissButton={false}
            position="top"
            size="small"
            triggerType="custom"
            content={(
              <StatusIndicator type="success">
                Copied to clipboard!
              </StatusIndicator>
            )}
          >
            <Button
              variant="primary"
              iconName="copy"
              data-customer={customerName}
              data-clipboard-target="emailContentToCopy"
              className="btn-clipboard"
              onClick={async () => {
                copyToClipboard('emailContentToCopy');
                await postUserAction('copy-to-clipboard');
              }}
            >
              Copy content to clipboard
            </Button>
          </Popover>
        </SpaceBetween>
      )}
    >
      {customerName}
    </Header>}>
      {/* <pre>
        {JSON.stringify(articles, null, 2)}
      </pre> */}
      <FormField label="Template">
        <Select
          selectedOption={selectedTemplate}
          onChange={async ({ detail }) => {
            setApplyingTemplate(true);
            console.log('Selected template: ', JSON.stringify(detail));
            setSelectedTemplate(detail.selectedOption);
            const templateId = detail.selectedOption.value; // e.g. template#1659364583197,
            const templateData = {
              customerName,
              articles,
              services: toCommaSeparatedList(serviceNames),
              fullName,
              firstName,
            };
            const templateResult = await applyTemplate(
              templateId,
              templateData,
            );
            setEmailBody(templateResult);
            setApplyingTemplate(false);
          }}
          options={templateOptions}
          selectedAriaLabel="Selected"
        />
      </FormField>
      
        {applyingTemplate === true ? (
            <Box margin="xl" padding="xl" textAlign="center">
              <span aria-label="loading">
                Applying template '{selectedTemplate.label}' <br />
                <Spinner size="big" />
              </span>
            </Box>
          ) : (
            <div id="emailContentToCopy">
              <div dangerouslySetInnerHTML={{ __html: emailBody }} />
              <Articles articles={articles} />
            </div>
        )}
    </Container>
    
  );
}

export default Customer;
